/**
 * Translations Query
 */

import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import convertWpUrlToPath from '@utils/convertWpUrlToPath';

const TranslationsQuery = ({ children }) => {
  const options = get(useStaticQuery(graphql`
    query TranslationsOptions {
      wordpressAcfOptions {
        options {
          translations_sources {
            authors
            summary
            conclusions
            source
            view_source
            source_from_the
            fair_ui_database
            fair_ui_database_url
          }
        }
      }
    }
  `),
  'wordpressAcfOptions.options', {});

  const sources = get(options, 'translations_sources', {}) || {};

  return children({
    sources: {
      ...sources,
      fair_ui_database_url: sources.fair_ui_database_url ? convertWpUrlToPath(sources.fair_ui_database_url) : null,
    },
  });
}

export default TranslationsQuery;
