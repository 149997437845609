/**
 * Source
 */

import React, {Fragment} from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { PageLayout, PageHeader } from '@layout';
import { Link } from '@components';
import { PageBuilder } from '@pagebuilder';
import { TranslationsQuery } from '@queries';

const Source = ({ data }) => {
  const pageChildren = get(data, 'allWordpressPage.nodes', []) || [];
  const page = get(data, 'wordpressPage', {})
  const pageTitle = get(page, 'title', null);
  const template = page.template ? page.template.replace('page-', '').replace('.php', '') : 'page';
  const path = get(page, 'path', null);
  const acf = get(page, 'acf', {}) || {}
  const seo = get(acf, 'seo', {});
  const siteMeta = {
    ...seo,
    pageTitle,
    path,
  }
  const pageBuilderData = get(acf, 'page_builder_page', null);
  const pageHeader = {
    eyebrow: get(acf, 'page_header_eyebrow', null),
    primary: get(acf, 'page_header_primary_title', null),
    secondary: get(acf, 'page_header_secondary_title', null),
    intro: get(acf, 'page_header_intro', null),
  }

  const source = get(acf, 'source', {}) || {};
  const { title, subtitle, author, conclusions, url } = source;

  return (
    <TranslationsQuery>
      {({ sources: sourcesTranslations }) => (
        <PageLayout
          template={template}
          siteMeta={siteMeta}
          pageHeader={(
            <PageHeader
              {...pageHeader}
              eyebrow={pageHeader.eyebrow || (
                <Fragment>
                  {`${sourcesTranslations.source_from_the}`}
                  {' '}
                  <Link href={sourcesTranslations.fair_ui_database_url}>{sourcesTranslations.fair_ui_database}</Link>
                </Fragment>
              )}
              primary={pageHeader.primary || `${title}${subtitle ? ':' : ''}`}
              secondary={pageHeader.secondary || subtitle}
            />
          )}
        >
          <div className="page__block page__block--text">
            {author && (
              <Fragment>
                <h2>{sourcesTranslations.authors}</h2>
                <p>
                  {author}
                </p>
              </Fragment>
            )}
            {conclusions && (
              <Fragment>
                <h2>{sourcesTranslations.conclusions}</h2>
                <p>
                  {conclusions}
                </p>
              </Fragment>
            )}
            {url && (
              <Fragment>
                <h2>{sourcesTranslations.source}</h2>
                <Link href={url} className="button" target="_blank">
                  {sourcesTranslations.view_source}
                </Link>
              </Fragment>
            )}
          </div>
          <PageBuilder data={pageBuilderData} meta={{ pageChildren, page }}  />
        </PageLayout>
      )}
    </TranslationsQuery>
  )
}

export default Source;

export const sourceQuery = graphql`
  query SourceById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      template
      title
      content
      path
      acf {
        page_header_eyebrow
        page_header_primary_title
        page_header_secondary_title
        page_header_intro
        seo {
          title
          description
          no_robots
          image {
            localFile {
              childImageSharp {
                fixed(width: 600, height: 315) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }

        components {
          key
          name
          component
        }

        page_builder_page {
          ...PageBuilder
        }

        source {
          url
          author
          author_short_version
          year
          title
          subtitle
          summary
          conclusions
          type {
            name
            slug
          }
          taxonomies {
            name
            slug
          }
        }
      }
    }
  }
`
