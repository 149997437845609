/**
 * Page Builder
 */

import React from 'react';
import get from 'lodash/get';
import { graphql, getModule } from 'gatsby';

const PageBuilder = ({ data, components, meta  }) => {
  const blocks = Array.isArray(data) ? data.map(block => ({ ...block,  meta })) : [];

  const renderBlock = (block, index) => {
    const component = get(components.find(c => c.key === block.__typename), 'component'); /*eslint-disable-line*/
    const Component = getModule(component);

    if (!Component) {
      return null;
    }

    return (
      <Component
        {...block}
        key={index}
      />
    )
  }

  return blocks.map(renderBlock);
}

export default PageBuilder;

export const pageBuilderFragment = graphql`
  fragment PageBuilder on Node {
    ...PageBuilderList
    ...PageBuilderText
    ...PageBuilderSpacer
    ...PageBuilderAnimation
    ...PageBuilderPhotoRow
    ...PageBuilderVideo
    ...PageBuilderPrototype
    ...PageBuilderCards
    ...PageBuilderBlockquote
  }
`